import { getDetailedDeviceInfo, IDisposable, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';
import { nanoid } from 'nanoid';

import { version } from '../../package.json';
import { eventBuffer } from './code/event-buffer';
import emitter, { DsmlEventMap } from './code/event-emitter';
import getActualToken from './code/get-actual-token';
import { logger } from './code/logger';
import type { ClientType, Environment, PartnerId, StorageType, UserPayload } from './code/user';
import getParsedUTMValues from './code/utm';
import { generateUuid } from './code/uuid';
import { StoredEventsDatabase } from './database/stored-events-database';
import { DsmlEventError } from './platform/errors';
import memoryStorage from './platform/memory-storage';
import { DsmlValue } from './request/abstract-send-event-request';
import BadResponseError from './request/api/errors/BadResponseError';
import createToken, { TokenResponseData } from './request/create-token-request';
import { sendDsmlEvent } from './request/send-single-event-request';
import updateToken from './request/update-token-request';

export interface DsmlAdditionalOptions {
  itemId?: string;
  episodeId?: string;
  kinomId?: string;
  timecode?: number;
}

export interface SendEventOptions {
  name: string;
  values: DsmlValue[];
  page?: string;
  options?: DsmlAdditionalOptions;
}

export interface DSMLOptions {
  partnerId: PartnerId;
  clientType: ClientType;
  variation?: 'ru' | 'am';
  password: string;
  storageType?: StorageType;
  appVersion?: string;
  debugMode?: boolean;
  eventSenderMode?: 'default' | 'stored';
}

interface LoggerType {
  log: (name: string, values?: DsmlValue[], payload?: DsmlAdditionalOptions) => void;
  warn: (error: unknown) => void;
  error: (error: unknown) => void;
}

export interface DSMLAnalyticApi {
  // events
  addEventListener<T extends keyof DsmlEventMap>(event: T, listener: (arg: DsmlEventMap[T]) => void): IDisposable;

  // public api
  sendEvent(data: SendEventOptions): this;

  // init
  init(options: DSMLOptions): Promise<this>;

  // set user payload
  setUser(payload: UserPayload): this;

  // set environment
  setEnvironment(env: Environment): this;

  // set external logger
  setLogger(logger: LoggerType): this;

  // set flags
  setFeatureFlags(flags: Record<string, string | boolean | number>): this;

  // reset flags
  resetFeatureFlags(): this;
}

export default class DsmlApi implements DSMLAnalyticApi {
  private refreshTokenPromise: Promise<TokenResponseData> | null = null;
  public version: string = version;

  private db: StoredEventsDatabase;

  constructor() {
    window.addEventListener('DOMContentLoaded', this.initialize);

    console.info('%c INFO', 'color: #33f', 'dsml.js version - ' + version);
  }

  private initialize() {
    const utmValues = getParsedUTMValues();

    if (utmValues.length > 0) {
      memoryStorage.set('utmValues', utmValues);
    }

    window.removeEventListener('DOMContentLoaded', this.initialize);
  }

  private async _handleBadResponseError(
    error: BadResponseError,
    eventPage: string,
    eventName: string,
    values: DsmlValue[],
    options?: DsmlAdditionalOptions,
  ): Promise<void> {
    const { response } = error;
    const { status } = response;

    const handledStatuses = Object.values(HTTPStatusCode);
    type StatusCodeHandler = {
      [key in HTTPStatusCode.Unauthorized | HTTPStatusCode.Forbidden]: Function;
    };

    const statusCodeHandlers: StatusCodeHandler = {
      [HTTPStatusCode.Unauthorized]: updateToken,
      [HTTPStatusCode.Forbidden]: createToken,
    };

    if (!handledStatuses.includes(status)) {
      return;
    }

    if (status === HTTPStatusCode.UnprocessableEntity) {
      logger?.error(`Unprocessable entity: event '${eventName}' called with ${JSON.stringify(values)}`);
      return;
    }

    if (status === HTTPStatusCode.InternalServerError) {
      logger?.error(
        `Internal server error occured while processing '${eventName}' called with ${JSON.stringify(values)}`,
      );
      return;
    }

    if (this.refreshTokenPromise) {
      const result = await this.refreshTokenPromise;

      if (!result.access_token) {
        logger?.error(`Cannot refresh dsml token: ${result}`);
        return;
      }

      await this._sendEvent(eventName, eventPage, values, options);
      return;
    }

    const handler = statusCodeHandlers[status as HTTPStatusCode.Unauthorized | HTTPStatusCode.Forbidden];

    if (!handler) {
      return;
    }

    try {
      this.refreshTokenPromise = await handler(await getActualToken());
      await this._sendEvent(eventName, eventPage, values, options);
      this.refreshTokenPromise = null;
    } catch (error) {
      this.refreshTokenPromise = createToken();
      await this.refreshTokenPromise;
      await this._sendEvent(eventName, eventPage, values, options);
      this.refreshTokenPromise = null;
    }
  }

  private _validateEvent(data: SendEventOptions): void {
    const { name, values, options, page } = data;

    if (!page) {
      console.warn("sendEvent: 'page' should be a non-empty string");
    }

    if (!name) {
      console.warn("sendEvent: 'name' should be a non-empty string");
    }

    if (!Array.isArray(values)) {
      console.warn("sendEvent: 'values' should be an array");
    }

    if (typeof options !== 'object' || Array.isArray(options) || options === null) {
      console.warn("sendEvent: 'options' should be an object");
    }
  }

  private _enrichEventPayload(values: DsmlValue[]): void {
    // Добавляем везде отправку utm_меток сохраненных
    if (memoryStorage.has('utmValues')) {
      const utmValues = memoryStorage.get('utmValues') as DsmlValue[];

      values.push(...utmValues);
    }

    if (memoryStorage.has('featureFlags')) {
      const flagValues = memoryStorage.get('featureFlags');

      values.push({ property: 'flags', value: flagValues });
    }

    values.push({ property: 'url', value: window.location.href });
  }

  private async _sendEvent(
    name: string,
    page: string,
    values: DsmlValue[],
    options?: DsmlAdditionalOptions,
  ): Promise<void> {
    this._enrichEventPayload(values);

    try {
      await sendDsmlEvent(name, page, values, options);
      logger?.log(name, page, values, options);

      // Если в временном буфере были события - также отправляем их в API
      if (eventBuffer.length > 0) {
        void eventBuffer.sendAll();
      }
    } catch (error) {
      if (error instanceof BadResponseError) {
        return this._handleBadResponseError(error, page, name, values, options);
      }

      if (error instanceof DsmlEventError) {
        return emitter.emit('error', error);
      }

      logger?.error(error);
    }
  }

  public addEventListener<T extends keyof DsmlEventMap>(event: T, listener: (arg: DsmlEventMap[T]) => void) {
    return emitter.on(event, listener);
  }

  public setUser(payload: UserPayload): this {
    // user id
    memoryStorage.set('userId', payload.userId);

    // user profileId
    memoryStorage.set('profileId', payload.profileId);

    if (payload.visitorId) {
      memoryStorage.set('visitorId', payload.visitorId);
    }

    if (payload.userIpV4) {
      memoryStorage.set('userIpV4', payload.userIpV4);
    }

    if (payload.userIpV6) {
      memoryStorage.set('userIpV6', payload.userIpV6);
    }

    return this;
  }

  public setEnvironment(env: Environment): this {
    memoryStorage.set('env', env || 'development');

    return this;
  }

  public setLogger(externalLogger: LoggerType): this {
    if (externalLogger) {
      logger.set(externalLogger as any);
    }

    return this;
  }

  public resetFeatureFlags(): this {
    memoryStorage.set('featureFlags', undefined);

    return this;
  }

  public setFeatureFlags(flags: Record<string, string | boolean | number>): this {
    const addedFlags = memoryStorage.get('featureFlags') || {};

    const updatedFlags = {
      ...addedFlags,
      ...flags,
    };

    memoryStorage.set('featureFlags', updatedFlags);

    return this;
  }

  public async init(options: DSMLOptions): Promise<this> {
    const uuid = generateUuid();

    const deviceInfo = await getDetailedDeviceInfo();

    const eventSenderMode: DSMLOptions['eventSenderMode'] = options.eventSenderMode || 'default';

    const { osVersion, browserVersion, browserName, osName, vendorName, modelName, modelCode } = deviceInfo;

    const normalizedBrowserVersion = `${browserName} v.${browserVersion}`;
    const normalizedOsVersion = `${osName} v.${osVersion}`;
    const normalizedDeviceType = `${vendorName} / ${modelName} / ${modelCode}`;

    memoryStorage.set('osVersion', normalizedOsVersion);
    memoryStorage.set('browserVersion', normalizedBrowserVersion);
    memoryStorage.set('deviceType', normalizedDeviceType);

    memoryStorage.set('sessionId', uuid);

    memoryStorage.set('partnerId', options.partnerId);
    memoryStorage.set('clientType', options.clientType);
    memoryStorage.set('password', options.password);
    memoryStorage.set('appVersion', options.appVersion);

    memoryStorage.set('storageType', options.storageType || 'cookie');
    memoryStorage.set('appVariation', options.variation || 'ru');

    memoryStorage.set('debugMode', options.debugMode || false);

    memoryStorage.set('eventSenderMode', eventSenderMode);

    if (eventSenderMode === 'stored') {
      this.db = new StoredEventsDatabase();
      await this.db.readAll();
    }

    return this;
  }

  public sendEvent(data: SendEventOptions): this {
    const { name, values, options, page = '' } = data;

    this._validateEvent(data);

    if (!name && memoryStorage.get('debugMode')) {
      throw new UnexpectedComponentStateError('event.name');
    }

    const isStoredMode = memoryStorage.get<DSMLOptions['eventSenderMode']>('eventSenderMode');
    const eventId = nanoid(4);

    if (isStoredMode && this.db) {
      void this.db.add(eventId, data);
    } else {
      void this._sendEvent(name, page, values, options);
    }

    return this;
  }
}
