<template>
  <slot></slot>
</template>

<script lang="ts" setup>
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { DisposableStore, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useErrorMediaAnalytics from '@PLAYER/player/modules/analytics/use-error-media-analytics';
import { UseDetectMediaTechSupportResult } from '@PLAYER/player/modules/hooks/use-native-playback-support';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import logger from '@PLAYER/player/modules/logger/default-logger';
import useSafeMediaPlayerPlugins from '@PLAYER/player/modules/plugin/use-safe-media-player-plugins';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import { MediaElementDOMInstance } from '@PLAYER/player/modules/video/media-element-dom-instance';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import AvplayMediaTech from '@PLAYER/player/tech/avplay/avplay-instance';
import DashMediaTech from '@PLAYER/player/tech/dash/dash-instance';
import HlsMediaTech from '@PLAYER/player/tech/hls/hls-instance';
import Html5MediaTech from '@PLAYER/player/tech/html5/html5-instance';
import MediaSourceTech from '@PLAYER/player/tech/media-source-tech';
import TataudioMediaSourceTech from '@PLAYER/player/tech/tataudio/tataudio-instance';
import type { HlsConfig } from 'hls.js';
import { storeToRefs } from 'pinia';
import { markRaw, onBeforeUnmount, onMounted } from 'vue';

const props = withDefaults(
  defineProps<{
    videoEl: MediaElementDOMInstance;
    onSetMediaSourceTech: (instance: MediaSourceTech) => void;
    techPlaybackType: UseDetectMediaTechSupportResult;
    autoStartLoad?: boolean;
  }>(),
  {
    autoStartLoad: false,
  },
);

const { isVOD, isLive } = useProjector();
const { isSmartTV } = usePlatform();
const { manifestUrl, manifestStartOffset } = storeToRefs(useManifestStore());
const videoInteractions = useVideoInteractions();
const errorMediaAnalytics = useErrorMediaAnalytics();

const plugins = useSafeMediaPlayerPlugins();

const disposableStore = new DisposableStore();

// Начинаем сразу подгружать видео, если это НЕ кином.
const autoStartLoad = (() => {
  if (isSmartTV) {
    return true;
  }

  if (props.autoStartLoad) {
    return true;
  }

  return isVOD.value || isLive.value;
})();

const mediaSourceTech: MediaSourceTech = (() => {
  if (!manifestUrl.value || props.techPlaybackType === 'native') {
    return new Html5MediaTech();
  }

  if (props.techPlaybackType === 'avplayer') {
    return new AvplayMediaTech();
  }

  if (props.techPlaybackType === 'tataudio') {
    return new TataudioMediaSourceTech();
  }

  if (props.techPlaybackType === 'dash') {
    return new DashMediaTech();
  }

  if (props.techPlaybackType === 'hls') {
    const hlsConfig: Partial<HlsConfig> = {
      autoStartLoad,
    };

    if (isLive.value) {
      hlsConfig.liveSyncDuration = ConstantsConfigPlayer.getProperty('liveTimeoutEdgeSeconds');
    }

    return new HlsMediaTech({ hlsConfig });
  }

  throw new UnexpectedComponentStateError('mediaSourceTech');
})();

markRaw(mediaSourceTech);
Reflect.apply(props.onSetMediaSourceTech, undefined, [mediaSourceTech]);

const initPlugins = () => {
  const onPlaybackInterrupted = () => errorMediaAnalytics.onPlayerInterrupted();
  const onPlaybackFailed = () => errorMediaAnalytics.onPlayerFatalError();

  // оборачиваем вызов плагинов наших в try/catch, чтобы ничего не поломать
  try {
    for (const Plugin of plugins) {
      const tech = (() => {
        if (mediaSourceTech instanceof Html5MediaTech) {
          return mediaSourceTech.mediaEl;
        }

        if (mediaSourceTech instanceof HlsMediaTech) {
          return mediaSourceTech.hlsInstance;
        }
      })();

      if (!tech) {
        throw new UnexpectedComponentStateError('tech');
      }

      const plugin = new Plugin(props.techPlaybackType as 'native' | 'hls', tech);

      plugin.emitter.on('playback-failed', onPlaybackFailed);
      plugin.emitter.on('playback-interrupted', onPlaybackInterrupted);

      disposableStore.add(plugin);
    }
  } catch (error) {
    logger.error(error);
  }
};

onMounted(async () => {
  const { videoEl } = props;

  if (!manifestUrl.value) {
    return;
  }

  await mediaSourceTech.init();

  mediaSourceTech.attachMedia(videoEl.domElement as HTMLVideoElement);
  mediaSourceTech.loadSource({ src: manifestUrl.value, offset: manifestStartOffset.value });
  videoInteractions.changeCurrentTime({ manually: true, seconds: manifestStartOffset.value });

  initPlugins();
});

onBeforeUnmount(() => {
  mediaSourceTech.dispose();
});
</script>
