<template>
  <root-video-z-index-layer
    :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Common, 'descriptionLayer')"
    :data-video-interaction="isPlaylistMode"
    :class="{
      [$style.descriptionLayer]: true,
      [$style.descriptionLayerIOSFullscreenVertical]: isIOSNativeFullscreenEnabled && isPortrait,
      [$style.descriptionLayerIOSFullscreenHorizontal]: isIOSNativeFullscreenEnabled && isLandscape,
      [$style.mediaSmartTv]: isSmartTV,
      [$style.mediaKinom]: isKinom,
      [$style.mediaPlaylistMode]: isPlaylistMode,
    }"
  >
    <header
      v-if="isTitleShown || isAdultContentWarningVisible || isRewindTipNotificationShown"
      :class="{ [$style.header]: true, [$style.headerPlaylist]: isPlaylistMode }"
    >
      <div v-if="isTitleShown" :style="{ visibility: isControlsPlaybackShown ? 'visible' : 'hidden' }">
        <p :class="{ [$style.title]: true, [$style.titleWeb]: isWeb, [$style.titleSmartTV]: isSmartTV }">
          {{ normalizedTitle }}
        </p>
        <p
          v-if="normalizedSubtitle"
          :class="{ [$style.subtitle]: true, [$style.subtitleWeb]: isWeb, [$style.subtitleSmartTV]: isSmartTV }"
        >
          {{ normalizedSubtitle }}
        </p>
      </div>
      <transition name="fade">
        <adult-content-warning
          v-if="isAdultContentWarningVisible && !isRewindTipNotificationShown"
          :class="$style.adult"
          :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Common, 'adultContentText')"
          :age-limit="(content as Media).ageLimit"
          :is-adult-text-exist="(content as Media).smokingAdText"
        />
      </transition>
      <rewind-tip-element v-if="isRewindTipNotificationShown" :class="$style.rewindTip" />
    </header>
    <template v-if="isTopControlsShown && !isRewindTipNotificationShown">
      <video-controls-hideable-view :class="$style.topControls">
        <remote-play-element v-if="isRemotePlayElementShown" :is-disabled="false" />
        <close-player-element v-if="isClosePlayerElementShown" :class="$style.closePlayerElement" />
      </video-controls-hideable-view>
    </template>
    <template v-if="isKinomDescriptionShown && isFullScreenEnabled">
      <video-controls-hideable-view>
        <div
          :class="{
            [$style.logoWrapper]: true,
            [$style.logoWrapperPlaylistMode]: isPlaylistMode,
          }"
          @click="mediaPlayRequestedAction.onRequestMedia()"
        >
          <app-image v-if="logo" :image-class="$style.logo" :alt="title" :src="logo" :width="$isMobile ? 150 : 550" />
          <cite v-else>
            <span>
              {{ playlistItemTitle }}
            </span>
          </cite>
          <div :class="$style.kinomButton">
            <media-kinom-primary-button :is-tiny-view="isTinyView" :text="renderMarkdown(primaryButtonText)" />
          </div>
        </div>
      </video-controls-hideable-view>
    </template>
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import useContentMediaButtonTexts from '@package/content-utils/src/code/content-media-button-texts';
import useMarked from '@package/content-utils/src/code/use-marked';
import { Channel, Media, Moment, Movie, Serial } from '@package/sdk/src/api';
import { OfferV2 } from '@package/sdk/src/api/offers/offer-v2';
import { isFullscreeniOSApiEnabled } from '@PLAYER/player/base/dom';
import VideoControlsHideableView from '@PLAYER/player/components/controls/VideoControlsHideableView.vue';
import AdultContentWarning from '@PLAYER/player/components/media-information/AdultContentWarning.vue';
import MediaKinomPrimaryButton from '@PLAYER/player/components/media-information/MediaKinomPrimaryButton.vue';
import RemotePlayElement from '@PLAYER/player/components/remote-playback/RemotePlayElement.vue';
import AppImage from '@PLAYER/player/components/ui/AppImage.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import ClosePlayerElement from '@PLAYER/player/components/video/ClosePlayerElement.vue';
import RewindTipElement from '@PLAYER/player/components/video/RewindTipElement.vue';
import useMediaPlayRequestedAction from '@PLAYER/player/modules/content/use-media-play-requested-action';
import useOrientation from '@PLAYER/player/modules/hooks/use-orientation';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import { translateContentButtonText } from '@PLAYER/player/modules/localization/translate';
import useSession from '@PLAYER/player/modules/session/use-session';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import {
  ElementTestIdentifierScope,
  getTestElementIdentifier,
} from '@PLAYER/player/modules/test/get-test-element-identifier';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import useVideoControlsVariables from '@PLAYER/player/modules/video/use-video-controls-variables';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const props = defineProps<{
  content: Media | Channel | Moment;
  logo?: string;
  title?: string;
  subtitle?: string;
  isPlaylistMode?: boolean;
  episodeNumberTitle?: string;
  isAdultContentWarningVisible?: boolean;
}>();

const { renderMarkdown } = useMarked();
const { isActiveSubscription, isAuth, offer, isPartnerSubscription, subscription, hasOfferTrial, promoOffer } =
  useSession();

const { isSmartTV, isWeb } = usePlatform();
const { isKinom, isVOD, isLive } = useProjector();
const { isTinyView, isSmallView } = useRootVideoElBounds();
const { getFullscreenMomentButtonText } = useContentMediaButtonTexts();
const { isInitialized, isFullScreenEnabled } = storeToRefs(useVideoUIStore());
const { isRewindTipNotificationShown } = storeToRefs(useVideoControlsStore());
const { currentPlaylistItem } = storeToRefs(usePlaylistStore());

const { isPortrait, isLandscape } = useOrientation();

const { isKinomDescriptionShown, isRemotePlayElementShown, isClosePlayerElementShown, isControlsPlaybackShown } =
  useVideoControlsVariables();

const mediaPlayRequestedAction = useMediaPlayRequestedAction();
const videoConfig = useVideoConfig();

const isTopControlsShown = computed(() => isWeb && (isVOD.value || isLive.value) && isInitialized.value);

const normalizedSubtitle = computed(() => props.subtitle || '');
const normalizedTitle = computed(() => props.title || '');

const playlistItemTitle = computed(() => {
  if (props.isPlaylistMode) {
    return currentPlaylistItem.value?.title || '';
  }

  return normalizedTitle.value;
});

const primaryButtonText = computed(() => {
  return translateContentButtonText(
    getFullscreenMomentButtonText({
      isAuth: isAuth.value,
      isActiveSubscription: isActiveSubscription.value,
      isPartnerSubscription: isPartnerSubscription.value,
      accessKind: (props.content as Movie | Serial).accessKind,
      offer: offer.value as OfferV2,
      subscribePromoTexts: videoConfig['content.subscribePromoTexts'],
      hasTrialOffer: hasOfferTrial.value,
      promoOffer: promoOffer.value,
      subscription: subscription.value,
      isPlaylistMode: props.isPlaylistMode,
      content: props.content as Media,
    }),
  );
});

const isIOSNativeFullscreenEnabled = computed(() => isFullscreeniOSApiEnabled && isFullScreenEnabled.value);

const isTitleShown = computed(() => {
  if (props.isPlaylistMode) {
    return true;
  }

  if (isRewindTipNotificationShown.value) {
    return false;
  }

  /**
   * Для Киномов - не отображаем
   */
  if (isKinom.value) {
    return false;
  }

  if (props.isAdultContentWarningVisible) {
    return !isSmallView.value;
  }

  return true;
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/fonts.scss' as webFonts;
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;

.descriptionLayer {
  top: var(--g-spacing-16);
  left: 0;
  right: 0;
  z-index: var(--z-index-media-description-view);
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: var(--g-spacing-16);
  padding-right: var(--g-spacing-16);
  pointer-events: none;
  justify-content: space-between;
}

.descriptionLayerIOSFullscreenVertical {
  top: 100px;
}

.descriptionLayerIOSFullscreenHorizontal {
  top: var(--g-spacing-60);
  left: var(--g-spacing-24);

  .topControls {
    margin-right: var(--g-spacing-24);
  }
}

.descriptionLayerSmall {
  max-width: 250px;
}

.descriptionLayerVOD {
  max-width: 500px;
}

.mediaKinom {
  top: initial;
  bottom: var(--g-spacing-16);
  left: var(--g-spacing-16);
  right: var(--g-spacing-16);
}

.mediaSmartTv {
  top: adjust.adjustPx(32px);
  left: adjust.adjustPx(32px);
}

.adult {
  margin-top: var(--g-spacing-16);
}

.mediaPlaylistMode {
  top: 0;
  bottom: 0;
  left: 0;
  right: var(--g-spacing-48);
}

.title {
  color: var(--color-notheme-text-primary);
}

.titleWeb {
  @include webFonts.WebHeadline-1();
}

.titleSmartTV {
  @include smartTvFonts.SmartTvHeadline-1();
}

.subtitle {
  margin-top: var(--g-spacing-8);
  text-transform: uppercase;
  color: var(--color-notheme-text-secondary);
}

.subtitleWeb {
  @include webFonts.WebHeadline-3();
}

.subtitleSmartTV {
  @include smartTvFonts.SmartTvHeadline-3();
}

.logo {
  width: 100%;
  max-width: 130px;
  height: 100%;
}

.kinomButton {
  margin-top: 0;
}

.logoWrapper {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: baseline;
}

.logoWrapperPlaylistMode {
  flex-direction: column;
  position: absolute;
  bottom: var(--g-spacing-16);
  left: var(--g-spacing-16);
  right: var(--g-spacing-16);
}

.header {
  margin-right: var(--g-spacing-16);
}

.headerPlaylist {
  position: absolute;
  top: var(--g-spacing-16);
  left: var(--g-spacing-16);
}

.topControls {
  display: flex;
  margin-left: auto;
  align-self: flex-start;
}

.closePlayerElement {
  margin-left: var(--g-spacing-8);
}

.rewindTip {
  position: absolute;
  top: var(--g-spacing-32);
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

@media (min-width: 767px) {
  .mediaKinom {
    bottom: 50px;
    left: 30px;
    right: 50px;
  }

  .logoWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .kinomButton {
    margin-top: var(--g-spacing-24);
  }

  .logo {
    max-width: 295px;
  }
}
</style>
