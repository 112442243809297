import { Disposable } from '@package/sdk/src/core';
import { IndexedDBStorage } from '@package/sdk/src/core/database/indexed-db';

export class StoredEventsDatabase extends Disposable {
  private readonly indexedDB = new IndexedDBStorage({
    tableName: 'stored-events',
    databaseName: 'dsml-js',
    timeoutForReading: 2500,
  });

  constructor() {
    super();
  }

  public add(key: string, value: unknown) {
    return this.indexedDB.write(key, value);
  }

  public remove(key: string) {
    return this.indexedDB.delete(key);
  }

  public read(key: string) {
    return this.indexedDB.read(key);
  }

  public readAll() {
    return this.indexedDB.readAll();
  }
}
