import { ApplicationError } from '@package/sdk/src/core';
import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';
import type { AxiosError } from 'axios';

export class ApiError extends ApplicationError {
  public status?: HTTPStatusCode;

  public readonly name = 'ApiError';

  constructor(error: AxiosError) {
    super();

    this.status = error.response?.status;
    // @ts-expect-error
    this.message = error.response?.data?.message || error.message;
  }

  public is(status: HTTPStatusCode) {
    return this.status === status;
  }

  public toJSON(): Record<string, any> {
    return {
      status: this.status,
      message: this.message,
    };
  }
}
