import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';

import { getUserTimezone } from '../code/date';
import { transformBodyToV2Version } from '../code/dsml-v2-request-transformer';
import { eventBuffer } from '../code/event-buffer';
import getActualToken from '../code/get-actual-token';
import sessionOrderCounter from '../code/session-order-counter';
import { ClientType } from '../code/user';
import { DsmlAdditionalOptions } from '../dsml';
import storage from '../platform/memory-storage';
import { getUTCTimestamp } from '../platform/std';
import {
  AbstractSendEventRequest,
  BaseDsmlEventPayload,
  DsmlValue,
  StorageFields,
} from './abstract-send-event-request';
import BadResponseError from './api/errors/BadResponseError';

const handledStatuses = [HTTPStatusCode.Unauthorized, HTTPStatusCode.Forbidden, HTTPStatusCode.InternalServerError];

class SendSingleEventRequest extends AbstractSendEventRequest {}

export interface DsmlRequestBodyParams {
  client_type?: ClientType;
  event_type_id?: string;
  item_id?: string;
  episode_id?: string;
  kinom_id?: string;
  profile_id?: string;
  visitor_id?: string;
  partner_id?: string;
  session_id?: string;
  os_version?: string;
  browser_version?: string;
  device_type?: string;
  user_id?: string;
  user_ip_v6?: string;
  user_ip_v4: string;
  session_order?: number;
  utc_timestamp?: number;
  user_timezone?: number;
  value?: DsmlValue[];
  app_version?: string;
}

type AdditionalOptions = {
  timecode?: number;
  item_id?: string;
  episode_id?: string;
  kinom_id?: string;
};

export async function sendDsmlEvent(name: string, page: string, values: DsmlValue[], options?: DsmlAdditionalOptions) {
  const utcTimestamp = getUTCTimestamp();

  const normalizedOptions: AdditionalOptions = {};

  if (options?.timecode) {
    normalizedOptions.timecode = options.timecode;
  }

  if (options?.itemId) {
    normalizedOptions.item_id = options.itemId;
  }

  if (options?.episodeId) {
    normalizedOptions.episode_id = options.episodeId;
  }

  if (options?.kinomId) {
    normalizedOptions.kinom_id = options.kinomId;
  }

  const timestamps: BaseDsmlEventPayload = {
    utc_timestamp: utcTimestamp,
    user_timezone: getUserTimezone(),
  };

  const token = await getActualToken();

  const body: DsmlRequestBodyParams = {
    ...normalizedOptions,
    ...timestamps,
    ...(storage.getAll() as unknown as StorageFields),
    event_type_id: name,
    session_order: sessionOrderCounter.getNext(),
    value: values,
  };

  const normalizedBody = transformBodyToV2Version(body, page);

  const request = new SendSingleEventRequest(normalizedBody);
  request.setToken(token);

  try {
    await request.send();
  } catch (error) {
    if (error instanceof BadResponseError && handledStatuses.includes(error.response.status)) {
      eventBuffer.add(normalizedBody);
    }

    throw error;
  }
}
