import { AnalyticEventNameNew, AnalyticPayloadLabelName } from '@package/sdk/src/analytics';
import { AnalyticValue } from '@PLAYER/player/modules/analytics/analytics';
import useAnalytics from '@PLAYER/player/modules/analytics/use-analytics';

export default function useErrorMediaAnalytics() {
  const analytics = useAnalytics();

  const getPlayerPayloadContent = (values: AnalyticValue[]) => {
    values.push({
      label: AnalyticPayloadLabelName.MaxQuality,
      value: 380,
    });

    values.push({
      label: AnalyticPayloadLabelName.Quality,
      value: 380,
    });

    values.push({
      label: AnalyticPayloadLabelName.ContainerSize,
      value: 380,
    });

    values.push({
      label: AnalyticPayloadLabelName.UserCap,
      value: 380,
    });

    return values;
  };

  const onPlayerInitialized = () => {
    analytics.sendAnalytic({
      name: AnalyticEventNameNew.AutoPlayerInitiated,
      values: getPlayerPayloadContent([]),
    });
  };

  const onPlayerStarted = () => {
    analytics.sendAnalytic({
      name: AnalyticEventNameNew.AutoPlayerStarted,
      values: getPlayerPayloadContent([]),
    });
  };

  const onPlayerInterrupted = () => {
    analytics.sendAnalytic({
      name: AnalyticEventNameNew.AutoPlayerInterrupted,
      values: getPlayerPayloadContent([]),
    });
  };

  const onPlayerFatalError = () => {
    analytics.sendAnalytic({
      name: AnalyticEventNameNew.AutoPlayerFatalError,
      values: getPlayerPayloadContent([]),
    });
  };

  return {
    onPlayerFatalError,
    onPlayerInitialized,
    onPlayerInterrupted,
    onPlayerStarted,
  };
}
